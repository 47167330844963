<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">商家优惠券</span>
		</div>
		<div class="bg-white p-2 m-2">
			<el-tabs @tab-click="handleClick" v-model="search.coupon_type">
				<el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.name" :name="item.value">
				</el-tab-pane>
			</el-tabs>
			<div class="d-flex flex-wrap align-items-center">
				<div>添加时间：</div>
				<el-date-picker style="width: 400px;" class="m-1" size="small" @change="changeTime" v-model="time"
					type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
				<div class="d-flex align-items-center ml-3">
					<div>是否发布：</div>
					<el-button @click="handleClickStatus(0)" :type="search.status == 0 ?'primary':''"
						size="mini">全部</el-button>
					<el-button @click="handleClickStatus(10)" class="ml-2" :type="search.status == 10 ?'primary':''" size="mini">否</el-button>
					<el-button @click="handleClickStatus(20)" class="ml-2" :type="search.status == 20 ?'primary':''" size="mini">是</el-button>
				</div>
				<div class="d-flex align-items-center ml-3">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="请输入搜索内容" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
				</div>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<!-- <el-table-column type="expand">
					<template slot-scope="scope">
						<div class="p-0 m-0">
							数量：总数量：{{scope.row.stock_num + scope.row.receive_num}} 限领数量：{{scope.row.limit_num}}
							已领数量：{{scope.row.receive_num}} 核销人数：1000
						</div>
						<div class="p-0 m-0">
							劵数据：浏览人数：100 浏览次数：1000 分享人数：1000 分享次数：1000
						</div>
					</template>
				</el-table-column> -->
				<el-table-column align="center" width="90" prop="coupon_id" label="劵ID"></el-table-column>
				<el-table-column align="center" label="劵类型" width="90">
					<template slot-scope="scope">
						<el-tag size="mini">{{ scope.row.coupon_type.text }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" label="劵名称" prop="name">
					<template slot-scope="scope">
						<template>
							{{ scope.row.name }}
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="所属商家" width="190">
					<template slot-scope="scope">
						<div class="d-flex align-items-center">
							<viewer>
								<img style="width: 45px;height: 45px;" :src="scope.row.merchant.logo">
							</viewer>
							<div class="ml-3" style="text-align: left;">
								{{scope.row.merchant.merchant_name}}<br>
								{{scope.row.merchant.mobile}}
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="有效期">
					<template slot-scope="scope">
						<template v-if="scope.row.expire_type == 10">
							<span>固定时间: {{scope.row.start_time}} - {{scope.row.end_time}}</span>
						</template>
						<template v-if="scope.row.expire_type == 20">
							<span>领取后 {{scope.row.start_day}} 天内有效</span>
						</template>
						<template v-if="scope.row.expire_type == 30">
							<span>领取后 {{scope.row.start_day}} 天- {{scope.row.end_day}} 天内有效</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="是否发布" width="90">
					<template slot-scope="scope">
						<el-tag size="mini" type="success">{{ scope.row.status.text }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" label="添加时间" width="180">
					<template slot-scope="scope">
						<span style="">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作">
					<template slot-scope="scope">
						<router-link :to="{name:'coupon/detail',params: {coupon_id:scope.row.coupon_id}}">
							<el-button type="text" size="mini" class="table-btn">详情</el-button>
						</router-link>
						<router-link class="ml-2" :to="{name:'coupon/record',params: {coupon_id:scope.row.coupon_id}}">
							<el-tooltip class="item" effect="dark" content="查看领取记录" placement="top">
								<el-button type="text" size="mini" class="table-last-btn">查看领取</el-button>
							</el-tooltip>
						</router-link>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				tabs: [{
						name: '全部',
						value: '0'
					},
					{
						name: '兑换券',
						value: '10'
					},
					{
						name: '满减券',
						value: '20'
					},
					{
						name: '折扣卷',
						value: '30'
					},
					{
						name: '代金券',
						value: '40'
					},
					{
						name: '裂变卷',
						value: '50'
					}
				],
				lists: [],
				search: {
					s: 'store/user.merchant/coupon',
					coupon_type: '0',
					merchant_user_id: this.$route.params.merchant_user_id,
					keyword: '',
					status: 0,
					page: 1,
					start_time: null,
					end_time: null,
				},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			handleClickStatus(status) {
				this.search.status = status;
				this.toSearch()
			},
			handleClick() {
				this.toSearch()
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
		},
	}
</script>

<style>

</style>
